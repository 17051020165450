import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import LoginOTPLayout from '../components/login/otp';

const LoginOTP = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.TWOFA}</title>
			</Helmet>
			<LoginOTPLayout />
		</>
	);
};

export default LoginOTP;
