import { QueryClient } from '@tanstack/react-query';
import { fetchWStateInfo } from '../api/payments';
import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import WithdrawLayout from '../components/withdraw';

export const withdrawLoader = () => {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	return new QueryClient().fetchQuery({
		queryKey: ['wStateInfo'],
		queryFn: () => fetchWStateInfo(wState),
		staleTime: Infinity
	});
};

const Withdraw = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.INDEX}</title>
			</Helmet>
			<WithdrawLayout />
		</>
	);
};

export default Withdraw;
