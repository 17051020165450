import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import RootLayout from '../components/root';
import { QueryClient } from '@tanstack/react-query';
import { fetchRootPath } from '../api/common';

export const rootLoader = () => {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;
	return new QueryClient().fetchQuery({
		queryKey: ['fetchRootPath'],
		queryFn: () =>
			fetchRootPath({
				wState: wState
			}),
		staleTime: Infinity
	});
};

const Root = () => {
	sessionStorage.setItem(
		'wstate',
		new URLSearchParams(window.location.search).get('wstate') as string
	);

	return (
		<>
			<Helmet>
				<title>{TITLES.LOADING.INDEX}</title>
			</Helmet>
			<RootLayout />
		</>
	);
};

export default Root;
