import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import LoginSelectTwoFaLayout from '../components/login/select-two-fa';

const LoginSelectTwoFa = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.SELECT_TWOFA}</title>
			</Helmet>
			<LoginSelectTwoFaLayout />
		</>
	);
};

export default LoginSelectTwoFa;
