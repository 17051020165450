import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import { QueryClient } from '@tanstack/react-query';
import { useLoaderData } from 'react-router-dom';
import { TEulaResponse } from '../types/types';
import ImpersonateLayout from '../components/login/impersonate';
import { fetchImpersonateEula } from '../api/login';

export const impersonateLoader = () => {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;
	const wStateInfo = JSON.parse(
		sessionStorage.getItem('wStateInfo') as string
	);
	return new QueryClient().fetchQuery({
		queryKey: ['impersonateEula'],
		queryFn: () =>
			fetchImpersonateEula({
				wstate: wState,
				f2xUserAgent: wStateInfo.f2xUserAgent
			}),
		staleTime: Infinity
	});
};

const LoginImpersonate = () => {
	const data = useLoaderData();

	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.IMPERSONATE}</title>
			</Helmet>
			<ImpersonateLayout eula={data as TEulaResponse} />
		</>
	);
};

export default LoginImpersonate;
