interface CountryModalItemProps {
	item: {
		code: string;
		name: string;
		link: string;
		phoneCode: string;
		isRestricted: boolean;
	};
	handleItemClick: (i: { code: string; imgUrl: string }) => void;
	handleModalOpen: () => void;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const CountryModalItem = ({
	item,
	handleItemClick,
	handleModalOpen,
	setSearchValue
}: CountryModalItemProps) => {
	const handleClick = () => {
		handleItemClick({
			code: item.code,
			imgUrl: item.link
		});
		handleModalOpen();
		setSearchValue('');
	};
	return (
		<div onClick={handleClick} className={'modalItem'}>
			<div key={item.code} className={'modalItemLogo'}>
				<img
					key={item.code}
					className={'modalItemImg'}
					src={item.link}
					alt={item.code}
				/>
				<h1 className={'modalItemLogoText'}>{item.name}</h1>
			</div>
		</div>
	);
};

interface OTPModalItemProps {
	item: {
		key: string;
		value: string;
	};
	handleItemClick: (i: { key: string; value: string }) => void;
	handleModalOpen: () => void;
}

const OTPModalItem = ({
	item,
	handleItemClick,
	handleModalOpen
}: OTPModalItemProps) => {
	const handleClick = () => {
		handleItemClick({
			key: item.key,
			value: item.value
		});
		handleModalOpen();
	};
	return (
		<div onClick={handleClick} className={'modalItem'}>
			<h1 className={'modalOtpItemCode'}>{item.key}</h1>
		</div>
	);
};

export { CountryModalItem, OTPModalItem };
