import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import ConfirmEmailLayout from '../components/login/confirm-email';

const LoginConfirmRecovery = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.CONFIRM_EMAIL}</title>
			</Helmet>
			<ConfirmEmailLayout />
		</>
	);
};

export default LoginConfirmRecovery;
