import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import WithdrawTwoFaLayout from '../components/withdraw/twofa';

const WithdrawTwoFa = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.TWOFA}</title>
			</Helmet>
			<WithdrawTwoFaLayout />
		</>
	);
};

export default WithdrawTwoFa;
