import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../common/buttons/index";
import { WhaleMaintainIcon } from "../../common/icons";

const RegistrationMaintainLayout = () => {
	const { t } = useTranslation();

	const buttonRef = useRef(null);

	const handleRefreshClick = () => {
		history.back();
	};

	return (
		<>
			<div className={"component"}>
				<div className={"innerComponent"}>
					<WhaleMaintainIcon className={""} />
					<h1 className={"maintainHeaderText"}>
						{t("registrationMaintainHeader")}
					</h1>
					<h1 className={"maintainHeaderBody"}>
						{t("registrationMaintainBody")}
					</h1>
					<CustomButton
						buttonRef={buttonRef}
						text={t("registrationMaintainButton") as string}
						type={"submit"}
						onClick={handleRefreshClick}
						className={"startButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default RegistrationMaintainLayout;
