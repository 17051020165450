import { Helmet } from "react-helmet";
import { TITLES } from "../types/titles";
import RedirectLayout from "../components/redirect";

const Redirect = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REDIRECT.INDEX}</title>
			</Helmet>
			<RedirectLayout />
		</>
	);
};

export default Redirect;
