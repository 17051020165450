import { useTranslation } from 'react-i18next';
import { Whale } from '../common/icons';
import { useEffect } from 'react';

const RedirectLayout = () => {
	const { t } = useTranslation();

	useEffect(() => {
		setTimeout(() => {
			window.close();
		}, 3000);
	}, []);

	return (
		<>
			<div className={'component'}>
				<div className={'innerComponent'}>
					<Whale className={'redirectWhale'} />
					<h1 className={'redirectHeaderText'}>
						{t('finishHeader')}
					</h1>
				</div>
			</div>
		</>
	);
};

export default RedirectLayout;
