import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import NewPasswordLayout from '../components/login/new-password';

const LoginNewPasswordRecovery = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.NEW_PASSWORD}</title>
			</Helmet>
			<NewPasswordLayout />
		</>
	);
};

export default LoginNewPasswordRecovery;
